.row {
  margin-left: 0;
  margin-right: 0;
}

.col-6 {
   padding-left: 0;
   padding-right: 0;
}

i.fas {
  cursor: pointer;
}

.header {
  padding: 30px;
  position: relative;
  color: white;
  i {
    font-size: 32px;
  }
  span {
    font-size: 32px;
    font-weight: 600;
  }
  margin-bottom: 0px;
}

.card {
  width: 100%;
  padding: 15px;
  font-weight: 600;
  margin: 0;
  .icons {
    * {
      cursor: pointer;
      margin-left: 16px;
    }
    color: #8762A6;
    font-weight: 800;
  }
  margin-bottom: 10px;
}

.main-card {
  padding: 15px 0;
  margin-bottom: 20px;
  .content {
    margin: 0 15px;
  }

  .qr {

    canvas {
      margin: 0 auto;
      height: 256px;
      margin-bottom: 1rem;
    }

    input {
      background: white;
    }

    span {
      cursor: pointer;
      background: white;
      i {
        color: #8762A6;
      }
    }
  }

  .bridge, .ops {
    .btn {
      background-color: #FFFFFF;
      color: white;
      font-weight: 500;
    }
  }
}


.balance {
  width: 100%;
  max-height: 60px;

  .avatar {
    max-width: 60px;
    margin-right: 10px;
    canvas {
      max-width: 60px;
      max-height: 60px;
    }
  }

  span {
    font-size: 16px;
    vertical-align: top;
  }

  div {
    font-weight: 500;
    font-size: 34px;
    line-height: 30px;
  }
}

.nav-card {
  i {
    color: #8762A6;
    line-height: 30px;
  }
  span {
    margin-left: 20px;
  }
}

.send-to-address {
  .form-group .identicon {
    display: block;
    margin: 0 auto;
  }
  label {
    font-size: 14px;
  }
  button {
    margin-top: 10px;
    font-size: 16px ;
  }
}

.bottom-text{
  margin-top: 20px;
  font-size: 16px;
  color: white;
  font-weight: 500;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .alert {
    font-size: 16px;
    margin: 10px;
  }
}

/** SLIDER STYLE OVERRIDES **/
.wyre-slider .input-range__slider {
    width: 18px;
    height: 18px;
    margin-left: -9px;
    margin-top: -11px;
}

@media only screen and (max-width: 500px) {
    .wyre-slider .input-range__slider {
        width: 24px;
        height: 24px;
        margin-left: -12px;
        margin-top: -15px;
    }
}

.wyre-slider .input-range__label--min,
.wyre-slider .input-range__label--max {
    bottom: -1.8rem;
}

@media only screen and (max-width: 500px) {
    .wyre-slider .input-range__label--min,
    .wyre-slider .input-range__label--max {
        bottom: -2.1rem;
    }
}

.wyre-slider .input-range__label {
    font-size: 16px!important;
    font-weight: normal!important;
    letter-spacing: .1px!important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.wyre-slider .input-range__label--value {
    display: none!important;
}


/** Wyre **/
.wyre-button--font-size {
    font-size: 16px;
}

.wyre-sublabel--font-size {
    font-size: 12px;
}

@media only screen and (max-width: 450px) {
    .wyre-button--font-size {
        font-size: 13px;
    }

    .wyre-sublabel--font-size {
        font-size: 10px;
    }
}
